import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ParentAuthGuardService {
  constructor(private auth: AuthService, private router: Router, private _snackBar: MatSnackBar) {}
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let AuthState: any = this.auth.isAuthenticated();
    const userFetched = await this.auth.userFetched();
    userFetched.get('role')
    if (AuthState && userFetched.get('role') === 'parent') {
        return true;
    } else {
      this.openSnackBar('Account awaiting approval. Please contact support.');
    }

    // not logged in so redirect to login page with the return url and return false
    this.router.navigateByUrl('/login');
    return false;
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 3000
    });
  }
}
