<div
  class="d-flex justify-content-center loader flex-column align-items-center"
  *ngIf="saving"
>
  <div>
    <mat-spinner color="accent"></mat-spinner>
  </div>
</div>

<form class="user" [formGroup]="consentForm" (ngSubmit)="saveStudentConsent()">
  <mat-card #TreatmentConsent class="m-3">
    <mat-card-header>
      <mat-card-title class="mt-3">Contract Summary</mat-card-title>
      <mat-card-subtitle>Informed Consent</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content class="m-3">
      <div class="row">
        <div class="col-md-12">
          <p *ngFor="let p of treatmentConsent; index as i; first as isFirst">
            <span *ngIf="isFirst"
              >I,
              <b
                ><u>{{ student.Name }} {{ student.Surname }}</u
                >,
              </b></span
            >{{ p }}
          </p>
        </div>
        <div class="col-md-12 d-flex flex-column align-items-center">
          <h2 id="Consent">Do you consent?</h2>
          <mat-radio-group
            class="bigRadio"
            aria-labelledby="Consent"
            formControlName="InformedTreatmentConsent"
          >
            <mat-radio-button class="example-radio-button m-3" [value]="true">
              Yes
            </mat-radio-button>
            <mat-radio-button class="example-radio-button m-3" [value]="false">
              No
            </mat-radio-button>
          </mat-radio-group>
          <mat-error
            *ngIf="
              consentForm.controls.InformedTreatmentConsent.errors ||
              (!student.InformedTreatmentConsent &&
                !consentForm.controls['InformedTreatmentConsent'].value)
            "
            >In order for you to be treatment we need your consent.</mat-error
          >
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="m-3">
    <mat-card-header>
      <mat-card-title class="mt-3"
        >Financial Responsibility of My Account</mat-card-title
      >
      <mat-card-subtitle>Informed Consent</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content class="m-3">
      <div class="row">
        <div class="col-md-12">
          <p *ngFor="let p of financialConsent; index as i; first as isFirst">
            <span *ngIf="isFirst"
              >I,
              <b
                ><u>{{ student.Name }} {{ student.Surname }}</u
                >,
              </b></span
            >{{ p }}
          </p>
        </div>
        <div class="col-md-12 d-flex flex-column align-items-center">
          <h2 class="text-center mb-2" id="FinancialConsent">
            Do you consent to the Financial responsibility involved?
          </h2>
          <mat-radio-group
            class="bigRadio"
            aria-labelledby="FinancialConsent"
            formControlName="InformedFinancialConsent"
          >
            <mat-radio-button class="example-radio-button m-3" [value]="true">
              Yes
            </mat-radio-button>
            <mat-radio-button class="example-radio-button m-3" [value]="false">
              No
            </mat-radio-button>
          </mat-radio-group>
          <mat-error
            *ngIf="
              consentForm.controls.InformedFinancialConsent.errors ||
              (!student.InformedFinancialConsent &&
                !consentForm.controls['InformedFinancialConsent'].value)
            "
            >In order for you to be treatment we need your consent.</mat-error
          >
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card class="text-center">
    <mat-card-content class="m-3">
      <div class="row">
        <div class="col-md-8 d-flex flex-column justify-content-center">
          <h2>Signature</h2>
        </div>
        <div class="col-md-4 d-flex flex-column align-items-center">
          <img #signatureElement />
          <button
            mat-raised-button
            color="accent"
            type="button"
            (click)="openDialog()"
          >
            Sign
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <p *ngIf="consentForm.invalid" class="text-error">
    Please ensure that you read and consent to the above
  </p>
  <mat-toolbar class="d-flex justify-content-between">
    <button type="button" mat-button (click)="back()">Back</button>
    <!-- <div class="d-flex"> -->
      <div *ngIf="student.Document?.source">
        <button
          class="mr-3"
          mat-flat-button
          type="button"
          *ngIf="student && student?.Document?.name"
          (click)="download()"
        >
          <fa-icon [icon]="faDownload"></fa-icon>Download Saved Consent
        </button>
      </div>
      <!-- <button *ngIf="signed" mat-button type="button" (click)="generatePDF('view')"><i class="fa fa-download fa-2x" aria-hidden="true"></i>&nbsp;Preview PDF</button> -->
      <button mat-raised-button type="submit" color="primary">
        {{ saving ? "Saving" : "Save Consent" }}
      </button>
    <!-- </div> -->
  </mat-toolbar>
</form>
