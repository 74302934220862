import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LocationService } from 'src/app/admin/services/location.service';
import { StudentService } from 'src/app/admin/services/student.service';
import { DataService } from 'src/app/services/data.service';
import { AlbumDialogComponent } from 'src/app/shared/album-dialog/album-dialog.component';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
  albumList = [];
  loading = false;
  constructor(private router: Router, private dataService: DataService, public dialog: MatDialog, private locationService: LocationService) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.dataService.albums$.subscribe(res => {
      this.albumList = res;
    })
    this.loading = false
  }

  async viewPhotos(albumId, albumTitle, coverPhoto): Promise<void>{
    this.locationService.openLocation = {name: albumTitle, id: albumId, coverPhoto};
    this.router.navigate(['/project', albumId]);
  }

}
