import { Injectable } from '@angular/core';
import { Parent } from 'backend/interfaces/parent.interface';
import { BehaviorSubject } from 'rxjs';
import * as Parse from 'parse';

import { Student } from 'backend/interfaces/student.interface';

@Injectable({
  providedIn: 'root'
})
export class ParentDataService {
  parent = new BehaviorSubject<Parent | null>(null);
  parent$ = this.parent.asObservable();
  students = new BehaviorSubject<Student[] | null>(null);
  students$ = this.students.asObservable();

  constructor() {
  }

  getParentProfile(email: string): Promise<Parent> {
    return Parse.Cloud.run('getParentByEmail', { email })
  }

  getStudentsByParentId(id: string, mainContact: boolean): Promise<Student[]> {
    return Parse.Cloud.run('getStudentsByParentId', { id, mainContact })
  }
}
