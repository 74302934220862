export enum ParseClasses {
    PATIENTS = 'Students',
    PARENTS = 'Parents',
    STUDENTS = 'Students',
    APPOINTMENTS = 'Appointments',
    SERVICES = 'Services',
    LOCATIONS = 'Locations',
    PRACTITIONERS = 'Practitioners',
    UPLOADS = 'Uploads',
    DAILYREPORT = 'DailyReports',
    MEALPLANS = 'MealPlans',
    MEALS = 'Meals',
    GROUPS = 'Groups',
    ACCOUNT = 'Accounts',
    MESSAGES = 'Messages',
    USER = "_User"
}
