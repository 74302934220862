import { Injectable } from '@angular/core';
import { Practitioner } from 'backend/interfaces/practitioner.interface';
import { Location } from 'backend/interfaces/location.interface';
import { Service } from 'backend/interfaces/service.interface';
import * as Parse from 'parse';
import { BehaviorSubject } from 'rxjs';
import { Group } from 'backend/interfaces/group.interface';
import { Parent } from 'backend/interfaces/parent.interface';
@Injectable({
  providedIn: 'root'
})
export class DataService {
  services = new BehaviorSubject<Service[] | null>(null);
  services$ = this.services.asObservable();
  locations = new BehaviorSubject<Location[] | null>(null);
  locations$ = this.locations.asObservable();
  albums = new BehaviorSubject<any[] | null>(null);
  albums$ = this.albums.asObservable();
  practitioners = new BehaviorSubject<Practitioner[] | null>(null);
  practitioners$ = this.practitioners.asObservable();
  groups = new BehaviorSubject<Group[] | null>(null);
  groups$ = this.groups.asObservable();
  parent = new BehaviorSubject<Parent | null>(null);
  parent$ = this.parent.asObservable();
  constructor() {
    // this.getServices();
    // this.getLocations();
  }

  getServices(displayLimit?: number, page?: number): Promise<void> {
    displayLimit = 50;
    page = 0;
    return Parse.Cloud.run('getServices', { displayLimit, page }).then((result) => {
      this.services.next(result);
    });
  }

  getGroups(displayLimit?: number, page?: number): Promise<void> {
    displayLimit = 50;
    page = 0;
    return Parse.Cloud.run('getGroups', { displayLimit, page }).then((result) => {
      this.groups.next(result);
    });
  }

  getLocations(): Promise<Location[]> {
    return Parse.Cloud.run('getLocations').then((result) => {
      this.locations.next(result);
    });
  }

  getPractitioners(): Promise<Practitioner[]> {
    return Parse.Cloud.run('getPractitioners').then((result) => {
      this.practitioners.next(result);
    });
  }

  getCalendarBusy(from: string, to: string): Promise<any>{
    return Parse.Cloud.run('getCalendarBusy', { from, to });
  }

  getAvailableTimeSlots(day: number, month: number, year: number, duration: number): Promise<any>{
    return Parse.Cloud.run('getAvailableTimeSlots', {day, month, year, duration})
  }

  getAvailableTimes(duration: number, day: number, month: number, year: number, location: string, preferredPractitioner: string): Promise<any>{
    return Parse.Cloud.run('getAvailableTimes', {duration, day, month, year, location, preferredPractitioner})
  }

  getAlbums(id: string): Promise<any[]> {
    return Parse.Cloud.run('getAlbumList', { id }).then((result) => {
      this.albums.next(result);
    });
  }

}
