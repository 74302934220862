import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StudentConsentFormComponent } from './student-consent-form/student-consent-form.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {MatCardModule} from '@angular/material/card';
import { SignaturePadComponent } from './signature-pad/signature-pad.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ProfileComponent } from './profile/profile.component';
import { MatButtonModule } from '@angular/material/button';
import { LetterheadComponent } from './letterhead/letterhead.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatStepperModule } from '@angular/material/stepper';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatRadioModule} from '@angular/material/radio';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { AppointmentFormComponent } from './appointment-form/appointment-form.component';
import { CancelDialogComponent } from './cancel-dialog/cancel-dialog.component';
import { CalendarComponent } from './calendar/calendar.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import {MatSliderModule} from '@angular/material/slider';
import { CameraComponent } from './camera/camera.component';
import { ProfilePictureDialogComponent } from './profile-picture-dialog/profile-picture-dialog.component';
import { WebcamModule } from 'ngx-webcam';
import { AppointmentsDialogComponent } from './appointments-dialog/appointments-dialog.component';
import { SearchLocationComponent } from './search-location/search-location.component';
import { LocationComponent } from './location/location.component';
import { MapComponent } from './map/map.component';
import { HttpClientModule } from '@angular/common/http';
import { GoogleMapsModule } from '@angular/google-maps';
import { PractitionerComponent } from './practitioner/practitioner.component';
import { SearchPractitionerComponent } from './search-practitioner/search-practitioner.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { AlbumDialogComponent } from './album-dialog/album-dialog.component';
import {MatDividerModule} from '@angular/material/divider';
import { ServiceComponent } from './service/service.component';
import { SearchServiceComponent } from './search-service/search-service.component';
import { SearchStudentComponent } from './search-student/search-student.component';
import { StudentProfileComponent } from './student-profile/student-profile.component';
import { SearchParentComponent } from './search-parent/search-parent.component';
import { DailyReportComponent } from './daily-report/daily-report.component';
import { PhotoAlbumComponent } from './photo-album/photo-album.component';
import { LettersComponent } from './letters/letters.component';
import { MealPlanComponent } from './meal-plan/meal-plan.component';
import { CheckInComponent } from './check-in/check-in.component';
import { SearchMealPlanComponent } from './search-meal-plan/search-meal-plan.component';
import { MealComponent } from './meal/meal.component';
import { SearchMealComponent } from './search-meal/search-meal.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { GroupComponent } from './group/group.component';
import { SearchGroupComponent } from './search-group/search-group.component';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { LightgalleryModule } from 'lightgallery/angular/13';
import { FileViewerDialogComponent } from './file-viewer-dialog/file-viewer-dialog.component';
import { GdriveComponent } from './gdrive/gdrive.component';
import { FacialFindDialogComponent } from './facial-find-dialog/facial-find-dialog.component';
import { GuageChartComponent } from './guage-chart/guage-chart.component';
import { ChatComponent } from './chat/chat.component';
import { ConversationComponent } from './chat/conversation/conversation.component';


export function momentAdapterFactory() {
  return adapterFactory(moment);
};

@NgModule({
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
    HttpClientModule
  ],
  declarations: [
    StudentConsentFormComponent,
    SignaturePadComponent,
    ProfileComponent,
    LetterheadComponent,
    CancelDialogComponent,
    CalendarComponent,
    AppointmentFormComponent,
    CameraComponent,
    ProfilePictureDialogComponent,
    CameraComponent,
    AppointmentsDialogComponent,
    SearchLocationComponent,
    LocationComponent,
    MapComponent,
    PractitionerComponent,
    SearchPractitionerComponent,
    AlbumDialogComponent,
    ServiceComponent,
    SearchServiceComponent,
    SearchStudentComponent,
    SearchParentComponent,
    StudentProfileComponent,
    DailyReportComponent,
    PhotoAlbumComponent,
    LettersComponent,
    MealPlanComponent,
    CheckInComponent,
    SearchMealPlanComponent,
    MealComponent,
    SearchMealComponent,
    GroupComponent,
    SearchGroupComponent,
    DeleteDialogComponent,
    FileViewerDialogComponent,
    GdriveComponent,
    FacialFindDialogComponent,
    GuageChartComponent,
    ChatComponent,
    ConversationComponent
  ],
  imports: [
    CommonModule, FormsModule, ReactiveFormsModule, MatCheckboxModule, FontAwesomeModule, MatCardModule, MatDialogModule, MatFormFieldModule, MatInputModule, MatButtonModule, MatToolbarModule, MatProgressSpinnerModule, MatStepperModule, MatExpansionModule, MatRadioModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }), MatSnackBarModule, MatProgressBarModule, MatDatepickerModule, MatNativeDateModule, MatSliderModule, WebcamModule, HttpClientModule, GoogleMapsModule, MatSidenavModule, MatSelectModule, MatChipsModule, MatDividerModule, DragDropModule, MatSelectModule, LightgalleryModule
  ],
  exports: [
    StudentConsentFormComponent,
    ProfileComponent,
    CalendarComponent,
    AppointmentFormComponent,
    SearchStudentComponent,
    SearchLocationComponent,
    SearchPractitionerComponent,
    CameraComponent,
    LocationComponent,
    MapComponent,
    PractitionerComponent,
    ServiceComponent,
    SearchServiceComponent,
    StudentProfileComponent,
    SearchParentComponent,
    DailyReportComponent,
    PhotoAlbumComponent,
    LettersComponent,
    MealPlanComponent,
    CheckInComponent,
    SearchMealPlanComponent,
    MealComponent,
    SearchMealComponent,
    GroupComponent,
    SearchGroupComponent,
    GuageChartComponent,
    ChatComponent
  ]
})
export class SharedModule { }

