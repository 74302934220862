<div
  class="d-flex justify-content-center loader flex-column align-items-center"
  *ngIf="loading"
>
  <div>
    <mat-spinner color="accent"></mat-spinner>
  </div>
</div>
<section>

      <div class="row m-0">
        <div class="col-md-6 d-flex flex-column p-3 bg-50perc-white">
          <div class="d-flex justify-content-between">
            <h1 class="mb-3">
              <fa-icon size="1x" [icon]="faCalendar"></fa-icon> Appointments
            </h1>
            <h2>{{ viewDate | date : "dd MMM yyyy, hh:mm" }}</h2>
          </div>
          <div>
            <button
              class="mb-2 mr-3 text-white btn-block"
              mat-raised-button
              color="primary"
              (click)="newAppointment()"
              type="button"
            >
              Make an Appointment
            </button>
            <mwl-calendar-week-view
              [viewDate]="viewDate"
              [events]="events"
              [dayStartHour]="minTime"
              [dayStartMinute]="00"
              [dayEndHour]="maxTime"
              [dayEndMinute]="00"
              (hourSegmentClicked)="newAppointment()"
            >
            </mwl-calendar-week-view>
          </div>

          <div class="mt-3 bg-50perc-white border-primary bg-primary"
        >
          <h1 class="text-white m-0 p-3">
            <fa-icon size="1x" [icon]="faPhoto"></fa-icon> Photo Albums
          </h1>
          <div
            class="d-flex justify-content-around flex-wrap align-items-center"
          >
            <div
              *ngFor="let album of albumList"
              class="flex-1 m-3 d-flex justify-content-center"
            >
              <div
                class="bg-image-container"
                [ngStyle]="{ 'background-image': album.coverPhotoBaseUrl }"
              >
                <!-- <div class="bg-image">
              <img [src]="album.coverPhotoBaseUrl">
          </div> -->
                <h1 class="bg-50perc-white text-primary">{{ album.title }}</h1>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div class="col-md-6 d-flex flex-column bg-50perc-white scrollable">
          <div class="flex-1">
            <div class="d-flex justify-content-between">
              <h1 class="mb-3">
                <fa-icon size="1x" [icon]="faCheckList"></fa-icon> Reports
              </h1>
            </div>
            <div class="m-3 flex-1 w-100 h-100">
              <ngx-timeline
                [events]="timelineEvents"
                [enableAnimation]="false"
              ></ngx-timeline>
            </div>
          </div>
        </div>
        </div>
</section>
