import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { PublicComponent } from './public.component';
import { ProjectsComponent } from './projects/projects.component';
import { ViewProjectComponent } from './view-project/view-project.component';
import { ContactComponent } from './contact/contact.component';
import { AboutComponent } from './about/about.component';


const routes: Routes = [
    { redirectTo: 'home', pathMatch: 'full', path: '' },
    {
        path: '',
        component: PublicComponent,
        children: [
            { path: 'home', component: HomeComponent },
            { path: 'projects', component: ProjectsComponent },
            { path: 'about', component: AboutComponent },
            { path: 'contact', component: ContactComponent },
            { path: 'project', component: ViewProjectComponent },
            { path: 'project/:id', component: ViewProjectComponent }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes), ],
    exports: [RouterModule]
})
export class AdminRoutingModule {}
