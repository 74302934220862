<div class="p-2">
    <!-- <div class="text-center">
        <h1 class="h4 text-gray-900 mb-4">Login</h1>
    </div> -->
    <div class="text-center mb-4">
        <button type="button" mat-button routerLink="/register" class="btn-block">Register</button>
    </div>
    <form class="user" [formGroup]="loginForm" (submit)="login()">
        <!-- <div class="form-group">
            <input name="email" type="email" class="form-control form-control-user" aria-describedby="emailHelp"
                placeholder="Email" [(ngModel)]="email">
        </div> -->
        <div class="d-flex flex-column">
            <mat-form-field appearance="outline" class="flex-1">
                <mat-label>Email</mat-label>
                <input matInput name="email" type="email" placeholder="Email" formControlName="email">
              </mat-form-field>
              <mat-form-field appearance="outline" class="flex-1">
                <mat-label>Password</mat-label>
                <input matInput [type]="!passwordVisible ? 'password' : 'text'" formControlName="password" name="password" placeholder="Password" id="password" name="password">
                <button type="button" mat-icon-button matSuffix (click)="passwordVisible = !passwordVisible" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passwordVisible">
                  <!-- <fa-icon [icon]="passwordVisible ? faEye : faEyeSlash"></fa-icon>  -->
                  <mat-icon>{{passwordVisible ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
              </mat-form-field>
              <div class="text-right">
                <button type="button" mat-button routerLink="/forgot-password">Forgot password?</button>
            </div>
        </div>

        <!-- <div class="form-group input-group">
            <input name="password" type="password" placeholder="Password" id="password" name="password" [type]="passwordVisible?'text':'password'" class="form-control form-control-user"
            [(ngModel)]="password" >
            <div class="input-group-append">
            <span class="input-group-text password-visibility" id="basic-addon2" (mousedown)="togglePassword(true)" (mouseup)="togglePassword(false)">
                <fa-icon class="eye-icon" *ngIf="!passwordVisible" [icon]="faEye"></fa-icon> 
                <fa-icon class="eye-icon" *ngIf="passwordVisible" [icon]="faEyeSlash"></fa-icon> 
            </span>
            </div>
        </div> -->
        <p class="invalid-field">{{errorMessage}}</p>
        <button mat-raised-button [disabled]="loading" color="accent" type="submit" class="btn-block">
            <span>Login</span>
        </button>

        <mat-progress-bar [mode]="loading ? 'indeterminate' : 'determinate'" [value]="0">
        </mat-progress-bar>
    </form>

</div>