


<div class="d-flex justify-content-center loader flex-column align-items-center" *ngIf="loading || !coverPhoto">
    <div>
        <mat-spinner color="accent"></mat-spinner>
    </div>
  </div>

<div class="p-5 m-3 cover-photo relative" [ngStyle]="{'background-image': coverPhoto}">
    <h1 class="bg-50perc-white p-3">{{albumTitle}}</h1>
</div>
<div *ngIf="projectPhotos.length > 0" class="row scrollable p-2 mr-2 flex-1">
    <div *ngFor="let a of projectPhotos" class="col-md-4 d-flex" >
        <mat-card class="p-0 bg-primary-light d-flex flex-column" >

            <mat-card-content class="m-0">
                <img (click)="viewPhotos(a.id, a.title)" class="img-fluid link w-100" [src]="a.baseUrl"/>
            </mat-card-content>
        </mat-card>
    </div>
</div>
