<div class="main-wrapper">

    <mat-toolbar class="d-flex justify-content-between bg-white">
        <div class="pl-3 pr-3">
            <img class="logo" src="assets/icon.png"/>
        </div>
        <div class="spacer"></div>
        <div class="desktop">
            <button mat-button routerLink="home" routerLinkActive="active">
                Home
            </button>
            <button mat-button routerLink="about" routerLinkActive="active">
                About
            </button>
            <!-- <button mat-button routerLink="projects" routerLinkActive="active">
                Gallery
            </button> -->
            <button mat-button routerLink="contact" routerLinkActive="active">
                Contact us
            </button>
        </div>
        <div class="desktop">
        <button color="primary" mat-button (click)="Login()">Login</button>
        </div>
        <div class="mobile">
            <button mat-stroked-button color="accent" [matMenuTriggerFor]="menu">MENU</button>
            <mat-menu #menu="matMenu">
                    <button mat-menu-item routerLink="home" routerLinkActive="active">
                        Home
                    </button>
                <button mat-menu-item routerLink="about" routerLinkActive="active">
                    About
                </button>
                <!-- <button mat-menu-item routerLink="projects" routerLinkActive="active">
                    Gallery
                </button> -->
                <button mat-menu-item routerLink="contact" routerLinkActive="active">
                    Contact us
                </button>
                <button mat-menu-item (click)="Login()">Login</button>
            </mat-menu>
        </div>

    </mat-toolbar>
    <div class="p-0 dashboard-container flex-1">
        <router-outlet></router-outlet>
    </div>

</div>


